<template>
    <div class="cs-block cs-landing-page-block">
        <div class="cs-base-block">
            <div class="container">
                <CSLandingPage :data="data" />
            </div>
        </div>
    </div>
</template>

<script>
    import {computed} from '../libs/common-fn';

export default {
        name: "LandingPage",
        components: { 
            CSLandingPage: () => import("../components/CSLandingPage.vue") 
        },
        props: {
            data: {
                type: Object,
                default: () => { },
            }
        },
        computed: computed('LandingPage')
    }

</script>

<style scoped lang="scss">

</style>